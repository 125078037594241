import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography, CircularProgress, Pet, Spacer, ReportChart, Alert, Icon, Progress } from '../components/library'
import { ImpactContext } from '../components/Layout'
import parseCACTData from '../utils/parseCACTData'
import hexToRgbA from '../utils/hexToRgbA'
import getGoalTitle from '../utils/getGoalTitle'
import parseSypmtomsData from '../utils/parseSypmtomsData'
import CACTLevels from '../utils/CACTLevels'
import { Container } from '@material-ui/core'
import parseSpirometerData from '../utils/parseSpirometerData'
import theme from '../theme'
import { UserContext } from '@parallelpublicworks/entitysync'
import CACTInfo from '../components/CACTInfo'
import FEV1Info from '../components/FEV1Info'
import ReportStreak from '../components/library/ReportStreak'

const useStyles = makeStyles(theme => ({
  list: {
    '& > * ': {
      marginTop: theme.spacing(3)
    }
  }
}))

const IndexPage = ({ location }: IndexProps) => {
  const classes = useStyles()
  const {
    userData,
    user,
    setUser,
    onboardingSeen,
    ancestor_path,
    currentWeek,
    today,
    hour,
    todaysCheckIn,
    profiles,
    isPastWeek,
    petLevel,
    gettingStartedDone,
    gettingStarted,
    gettingStartedConfig,
    previousWeek
  } = useContext(ImpactContext)
  const userContext = useContext(UserContext)
  const [symptomsData, setSymptomsData] = React.useState(null)
  const [spirometerData, setSpirometerData] = React.useState(null)
  const [cActData, setCActData] = React.useState(null)
  const [petLevelPadding, setPetLevelPadding] = React.useState(3)

  React.useEffect(() => {
    let padding
    switch (petLevel) {
      case 5:
        padding = 8
        break
      case 2:
        padding = 4
        break
      case 3:
        padding = 5.5
        break
      case 4:
        padding = 6.5
        break
      default:
        padding = 2
        break
    }
    setPetLevelPadding(padding)
  }, [petLevel])

  React.useEffect(() => {
    if (userData !== null) {
      if (symptomsData === null) setSymptomsData(parseSypmtomsData(userData))
      if (spirometerData === null) setSpirometerData(parseSpirometerData(userData))
      if (cActData === null) setCActData(parseCACTData(userData))
    }
  }, [userData])

  if (!userData || !user || symptomsData === null || spirometerData === null || cActData === null || currentWeek === null) {
    return <CircularProgress />
  }
  return (
    <Container maxWidth="md">
      <Spacer amount={petLevelPadding * 1.5} />
      <Typography
        variant="caption"
        uppercase
        shade="dark"
        color={isPastWeek ? theme.palette.orange.main : 'gray'}
      >{`IMPACT WEEK ${currentWeek.number + 1}, ${currentWeek.label}`}</Typography>
      <Typography variant="h1" shade="dark">
        {today.toFormat('cccc, LLL d')}
      </Typography>
      <Spacer amount={petLevelPadding * 0.5} />
      <div className={classes.list}>
        <Pet horizontal key="home-pet" id="home-pet">
          {/* <Typography variant="body1">Good {(hour<12 && "morning" || hour<18 && "afternoon" || "evening")}! Remember to take your regular medication.</Typography> */}
          {petLevel <= 1 ? (
            <Typography variant="body1">I'm hungry, help me grow by clicking get started!.</Typography>
          ) : (
            <Typography variant="body1">
              {/* Good {(hour < 12 && 'morning') || (hour < 18 && 'afternoon') || 'evening'}! */}
              Welcome back! 
              Remember to take your regular medication.
            </Typography>
          )}
        </Pet>

        {!isPastWeek && (!todaysCheckIn || todaysCheckIn?.attributes?.field_finish !== true) && gettingStartedDone && (
          <Alert
            title="Daily Check-In"
            icon={<Icon name="watch" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="lg" />}
            to="/import-symptoms"
            color="secondary"
          >
            <span>
              It's time for an asthma check-in {profiles?.child?.name && profiles.child.name.length > 0 && `with ${profiles.child.name}`}.
            </span>
          </Alert>
        )}

        {!gettingStartedDone && (
          <Alert
            title="Get Started"
            icon={<Progress step={gettingStarted.length} total={gettingStartedConfig.length} color="light" />}
            to={'/getting-started'}
            color="primary"
          >
            <ol>
              {gettingStartedConfig.map(a_conf => {
                return gettingStarted.includes(a_conf.key) ? (
                  <li key={a_conf.key}>
                    <del>{a_conf.label}</del>
                  </li>
                ) : (
                  <li key={a_conf.key}>{a_conf.label}</li>
                )
              })}
            </ol>
          </Alert>
        )}

        {gettingStartedDone && previousWeek && !previousWeek.todo_done && currentWeek.number > 0 && (
          <Alert
            title={`Week ${previousWeek.number + 1} To Do`}
            icon={<Progress step={previousWeek.todo_progress} total={previousWeek.todo_total} color="light" />}
            to={'/weekly-to-do'}
            color={isPastWeek ? 'orange' : 'primary'}
          >
            <ol>
              {previousWeek.spiro_done ? (
                <li>
                  <del>Spirometry Test</del>
                </li>
              ) : (
                <li>Spirometry Test</li>
              )}
              {previousWeek.cact_done ? (
                <li>
                  <del>Take C-ACT</del>
                </li>
              ) : (
                <li>Take C-ACT</li>
              )}
              {previousWeek.goals.map(uuid => {
                const goal = userData[uuid]
                if (!goal) return <></>
                return previousWeek.goals_checked_in.includes(uuid) ? (
                  <li>
                    <del>{getGoalTitle(goal, true)}</del>
                  </li>
                ) : (
                  <li>{getGoalTitle(goal, true)}</li>
                )
              })}
            </ol>
          </Alert>
        )}

        <ReportChart
          title="Daily Check-In"
          id="asthma-check-in-symptoms-overview"
          icon="lungs"
          data={symptomsData}
          to="/asthma-check-in-detail-view"
          toOverview="/reports"
          unit="integer"
          glanceDescription={{ singular: 'Symptom', plural: 'Symptoms' }}
          disableLevelColors
        />

        <ReportStreak
          title="Medication"
          id="medication-streak-overview"
          icon="inahler"
          data={symptomsData}
          unit="integer"
          glanceDescription={{ singular: 'Streak', plural: 'Streaks' }}
          disableLevelColors
        />

        <ReportChart
          title="FEV1"
          id="sample2"
          help="FEV1"
          helpModal={<FEV1Info />}
          icon="spirometer"
          icon_color="pink"
          data={spirometerData}
          unit="percent"
          to="/spirometry-detail-view"
          max={1}
          levels={[
            { from: 0, to: 0.8, level: 0, label: ' poorly controlled' },
            { from: 0.801, to: 999, level: 4, label: ' well controlled' }
          ]}
        />

        <ReportChart
          id="c-act-overview"
          title="C-ACT"
          help="C-ACT"
          helpModal={<CACTInfo />}
          icon="clipboard"
          icon_color="blue"
          data={cActData}
          levels={CACTLevels}
          max={27}
          to="/c-act-detail-view"
          toOverview="/reports/c-act"
          unit="integer"
        />
      </div>
    </Container>
  )
}

const indexPropTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired
  }).isRequired
}
type IndexProps = PropTypes.InferProps<typeof indexPropTypes>
IndexPage.propTypes = indexPropTypes

export default IndexPage
