import React, {useState, useContext} from 'react'
import { makeStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import {Card, ReportGlance, Icon, Button, CircularProgress} from './';
import theme from '../../theme'
import { ImpactContext } from '../Layout';
import CalendarStreak from './calendar-streak';

const useWrapperStyles = makeStyles((theme) => ({
    root: {
        '&.expanded': {
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(2),
            },
        }
    },
    calendar: {
        '&.expanded': {
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(2),
            },
        }
    }
}))

const useStyles = makeStyles((theme) => ({
    glance: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    topbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 0, 3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 0, 2),
        },
    },
    tabs: {
        padding: theme.spacing(0.5),
        borderRadius: 4,
        backgroundColor: theme.palette.gray.lightest,
        '& .MuiButton-root': {
            borderRadius: 4,
            padding: theme.spacing(0.5, 2.5),
            minWidth: `60px !important`,
            color: theme.palette.gray.dark,
            '&.active': {
                background: 'white'
            },
            '&.inactive': {
                background: 'transparent !important'
            },
            '& + .MuiButton-root': {
                marginLeft: theme.spacing(0.5),
            }
        }
    },
    btnArrow: {
        paddingLeft: `0 !important`,
        paddingRight: `0 !important`
    },
    link: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            '& > div': {
                backgroundColor: `${ theme.palette.secondary.lightest } !important`,
                transform: 'scale(1.2)',
                '& i': {
                    color: `${ theme.palette.secondary.main } !important`,
                }
            }
        }
    },
    calendarContainer: {
        paddingTop: theme.spacing(2),
    }
}))

function Wrapper({expanded, children}){
    const classes = useWrapperStyles()
    if(!expanded) return <div className={classes.root}>{children}</div>
    else return  <Card className={`${classes.root} expanded`} hideOverflow>{children}</Card>
}


function ReportStreak({
    icon,
    icon_color = 'blue',
    defaultMode = 'collapsed',
    hideTopbar
}) {

    const { today, currentStreaks, asthmaCheckinItems } = useContext(ImpactContext)
    const [medInfo, setMedInfo] = useState(null)
        
    const classes = useStyles()
    const icon_colors = {
        fg: theme.palette[icon_color].light,
        bg: theme.palette[icon_color].lighter,
    }
    
    const [mode, setMode] = useState(defaultMode) //collapsed, week, month

    function showMedInfo(dayInfo) {
        let dailyInfo = asthmaCheckinItems.find(item => {
            let itemDateTime = DateTime.fromISO(item.attributes.field_checkin_date_iso)
            return itemDateTime.toFormat('yyyy-MM-dd') === dayInfo.dateStr
        })

        if(!dailyInfo) return setMedInfo(null)
        
        let dailyMedCount = 0
        if(dailyInfo.attributes.field_medication_used == 'yes') {
            dailyMedCount++
            if(dailyInfo.attributes.field_medication_use_period && dailyInfo.attributes.field_medication_use_period.length <= 2) {
                dailyMedCount = dailyInfo.attributes.field_medication_use_period.length
            }
        }

        let rescueMedCount = 0
        if(dailyInfo.attributes.field_inhaler_used == 'yes') {
            rescueMedCount++
            if(+dailyInfo.attributes.field_inhaler_doses > 0) {
                rescueMedCount = +dailyInfo.attributes.field_inhaler_doses
            }
        }

        setMedInfo({
            dailyMedCount,
            rescueMedCount,
            date: DateTime.fromJSDate(dayInfo.date)
        })
    }
    
    if(mode === null) return <CircularProgress />

    return (
        <Wrapper expanded={mode !== 'collapsed'}>
            {mode !== 'collapsed' && !hideTopbar && (
                <div className={classes.topbar}>
                    {icon && <Icon name={icon} size="sm" {...icon_colors} className={classes.icon} />}
                    <div className={classes.tabs}>
                        <Button inline size="small" variant="contained" className={`${mode === 'week' ? 'active' : 'inactive'}`} color="default" onClick={(e) => {
                            if(mode === 'week'){
                                moveWeekFocus('next')
                            }else{
                                setMode('week')
                            }
                        }}>{`Week`}</Button>
                        <Button inline size="small" variant="contained" className={`${mode === 'month' ? 'active' : 'inactive'}`} color="default" onClick={(e) => setMode('month')}>Month</Button>
                    </div>
                    <button onClick={(e) => setMode('collapsed')} className={classes.link}><Icon name="stats" fg="white" bg={theme.palette.purple.main} /></button>
                </div>
            )}
            {!medInfo ? (<ReportGlance
                title="Medication"
                icon={icon}
                icon_color={icon_color}
                number={'' + currentStreaks} 
                description={"day streak"}
                level={currentStreaks}
                note={"Reported " + today.toFormat('yyyy LLL dd')}
                onClick={mode == 'collapsed' ? () => setMode('month') : null}
            />) : (<ReportGlance
                    title="Medication"
                    icon={icon}
                    icon_color={icon_color}
                    number={'' + medInfo.dailyMedCount}
                    description={`daily med${medInfo.dailyMedCount > 1 ? 's' : ''}, ${medInfo.rescueMedCount} rescue med${medInfo.rescueMedCount > 1 ? 's' : ''}`}
                    note={medInfo.date.toFormat('cccc d LLLL y')}
                    to={'reports?date=' + medInfo.date.toFormat('yyyy-MM-dd')}
                />)}
            {mode !== 'collapsed' && (
                <div className={classes.calendarContainer}>
                    <CalendarStreak
                        asthmaCheckinItems={asthmaCheckinItems}
                        week={mode == 'week'}
                        onDayClick={showMedInfo}
                        />
                </div>
            )}

        </Wrapper>
    )
}

export default ReportStreak
