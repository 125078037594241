import { DateTime } from 'luxon'

function parseSypmtomsData(userData){
    let symptoms_data = []
    const symptoms = []
    const check_in_uuids = Object.keys(userData).filter((uuid) => userData[uuid].type === 'node--asthma_check_in' && typeof userData[uuid].attributes !== 'undefined')
    if(check_in_uuids.length > 0){
        for (const uuid of check_in_uuids) {
            const entity = userData[uuid]
            const id = typeof entity.id !== 'undefined' ? entity.id : entity.local_id
            let entity_symptoms_data = null
            if(entity?.attributes?.field_symptoms){
                entity_symptoms_data = JSON.parse(entity.attributes.field_symptoms)
            }
            if(entity_symptoms_data && Array.isArray(entity_symptoms_data) && entity_symptoms_data.length > 0){
                symptoms_data = [...symptoms_data, ...entity_symptoms_data.map((smptm) => ({...smptm, uuid: id}))]
            }else{
                symptoms_data = [...symptoms_data, {time: entity.attributes.field_checkin_date_iso, clear: true, uuid: id }]
            }
        }
    }
    if(symptoms_data.length > 0){
        const symptoms_by_day = {}
        for (const a_symptom of symptoms_data) {
            const a_symptom_time = DateTime.fromISO(a_symptom.time)
            const a_symptom_day = a_symptom_time.toFormat('yyyy-MM-dd')
            if(!symptoms_by_day[a_symptom_day]) symptoms_by_day[a_symptom_day] = []
            symptoms_by_day[a_symptom_day].push(a_symptom)
        }
        for (const day_str of Object.keys(symptoms_by_day)) {
            const day_symptoms = symptoms_by_day[day_str]
            const last_day_sypmtom = day_symptoms[(day_symptoms.length - 1)]
            const day_date = DateTime.fromISO(last_day_sypmtom.time)
            symptoms.push({
                value: day_symptoms.filter(smptm => !smptm.clear).length,
                date: day_date,
                ts: day_date.toMillis(),
                uuid: last_day_sypmtom.uuid
            })
        }
    }
    return symptoms
}

export default parseSypmtomsData