import React from 'react'
import { ScoreScale } from './library'

function FEVScale({hideBar = false}) {
    return (
        <ScoreScale
            hideBar={hideBar}
            levels={[
                {from: 0, to: 0.80, level: 0, label: '0% to 80%: poorly controlled'},
                {from: 0.801, to: 999, level: 4, label: '80% to 100%: well controlled'},
            ]}
        />
    )
}

export default FEVScale
