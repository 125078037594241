import getCACTS from './getCACTS'
import { calculateScore } from "../utils/cact-scoring";
import getLevel from '../utils/getLevel'
import CACTLevels from "../utils/CACTLevels";

import { DateTime } from 'luxon';

function parseCACTData(userData, format = 'chart') {
    const cacts = getCACTS(userData)
    return cacts.map((cact) => {
        return parseCACTItemFn(cact, format)
    })
}

function parseCACTItemFn(entity, format = 'chart'){
    const score = calculateScore(entity)
    const dt = DateTime.fromISO(entity.attributes.field_test_date_iso)
    const id = typeof entity.id !== 'undefined' ? entity.id : entity.local_id
    let item = {}
    if(format === 'chart'){
        item = {
            value: score,
            date: dt,
            ts: dt.ts / 1000,
            uuid: id
        }
    }else if(format === 'report'){
        const level = getLevel(score, CACTLevels, 27, true)
        item = {
            time: entity.attributes.field_test_date_iso,
            score,
            level: level?.level ? level.level : 0,
            to: ['/c-act-detail-view', entity],
        }
    }
    return item
}

export const parseCACTItem = parseCACTItemFn

export default parseCACTData