import React from 'react'
import FEVScale from './FEVScale'
import Typography from './library/Typography'

function FEV1Info() {
    return (
        <>
            <Typography variant="subtitle2" shade="dark">What is FEV1?</Typography>
            <Typography shade="dark">A measure of lung function. </Typography>
            
            <Typography variant="subtitle2" shade="dark">What does the score mean?</Typography>
            <Typography shade="dark">Scores less than 80% indicate worse asthma control.</Typography>
            <FEVScale />
        </>
    )
}

export default FEV1Info
