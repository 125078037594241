import React, { useEffect } from 'react';
import { DateTime } from 'luxon'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import './index.css'

function getMedEvents(asthmaCheckinItems, color = '#37BFBF', search_field = 'field_medication_used') {
  let iterateDate = asthmaCheckinItems[0]?.attributes?.field_checkin_date_iso ? DateTime.fromISO(asthmaCheckinItems[0].attributes.field_checkin_date_iso) : null

  let events = [];

  let event = {
    title: search_field == 'field_medication_used' ? 'Daily meds' : 'Rescue meds',
    start: null,
    end: null,
    color,
  };

  while (iterateDate && iterateDate.startOf("day") <= DateTime.now().startOf("day")) {
    let checkinFound = asthmaCheckinItems.find(item => {
      let itemDateTime = DateTime.fromISO(item.attributes.field_checkin_date_iso)
      return iterateDate.hasSame(itemDateTime, 'day') && item.attributes[search_field] == 'yes'
    });
    
    if(checkinFound) {

      if (!event.start) {
        event = {
          ...event,
          start: iterateDate,
          end: iterateDate
        }
      } else {
        event = {
          ...event,
          end: iterateDate
        }
      }

      if(DateTime.now().hasSame(iterateDate, 'day') && event.end) {
        events = [...events, event]
      }
      
    } else {
      events = [...events, event]

      event = {
        ...event,
        start: null,
        end: null
      }
    }
    iterateDate = iterateDate.plus({ days: 1 })
  }

  events = events.map(event => {
    return {
      ...event,
      start: event.start ? event.start.toFormat('yyyy-MM-dd') : '',
      end: event.end ? event.end.plus({ days: 1 }).toFormat('yyyy-MM-dd') : ''
    }
  })
  
  return events;
}

export default function CalendarStreak({ asthmaCheckinItems = [], onDayClick, week = false }) {

  let eventsDailyMedEvents = getMedEvents(asthmaCheckinItems);
  // let eventsRescueMedEvents = getMedEvents(asthmaCheckinItems, '#FF8723', 'field_inhaler_used');
  let eventsRescueMedEvents = [];

  let calendarRef = React.createRef()

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi()
    calendarApi.changeView(week ? "listWeek" : "dayGridMonth")
  }, [week])

  const eventDidMount = (info) => {
    var timeEl = info.el.getElementsByClassName('fc-list-event-time')[0];
    if (timeEl) {
      let headervalue = timeEl.getAttribute("headers")
      let dateStr = headervalue.slice(-10)
      let date = new Date(dateStr)
      if(!isNaN(date)) {
        date = DateTime.fromJSDate(date)
        let dailyInfo = asthmaCheckinItems.find(item => {
          let itemDateTime = DateTime.fromISO(item.attributes.field_checkin_date_iso)
          return itemDateTime.toFormat('yyyy-MM-dd') === dateStr
        })

        let dailyMedCount = 0
        if(dailyInfo && info.event.title === 'Daily meds' && dailyInfo.attributes?.field_medication_used == 'yes') {
          dailyMedCount = 1;
          if(dailyInfo.attributes.field_medication_use_period && dailyInfo.attributes.field_medication_use_period.length <= 2) {
              dailyMedCount = dailyInfo.attributes.field_medication_use_period.length
          }

          timeEl.innerHTML = dailyMedCount;
        }

        let rescueMedCount = 0;
        if(dailyInfo && info.event.title === 'Rescue meds' && dailyInfo.attributes?.field_inhaler_used == 'yes') {
          rescueMedCount = 1;
          if(+dailyInfo.attributes.field_inhaler_doses > 0) {
              rescueMedCount = +dailyInfo.attributes.field_inhaler_doses
          }

          timeEl.innerHTML = rescueMedCount;
        }
      }
    }
  }

  return <FullCalendar
    selectable
    ref={calendarRef}
    headerToolbar={{ start: 'prev', center: 'title', end: 'next' }}
    eventSources={[{ events: [...eventsDailyMedEvents, ...eventsRescueMedEvents] }]}
    plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
    initialView={week ? "listWeek" : "dayGridMonth"}
    dateClick={(info) => {
      if (onDayClick) {
        onDayClick(info)
      }
    }}
    eventDidMount={eventDidMount}
  />
}
