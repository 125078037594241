import parseSpirometerReports from './parseSpirometerReports'

function parseSpirometerData(userData) {
    const reports = parseSpirometerReports(userData)
    let data = reports.map(report => {
        let item = {
            date: report.date,
            // value: report.fev1.actual,
            value: report.fev1.predicted / 100,
            ts: report.date.ts,
            uuid: report.uuid
        }
        return item
    })

    return data
}

export default parseSpirometerData